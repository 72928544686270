<template>
  <section>
    <page-heading
      title="Rejoignez l'enquête de demandes de soins non programmées des officines du Grand Est"
      subtitle="Votre participation est rémunérée."
      :image="currentUser ? 1 : 2"
    >
      <my-btn
        v-for="(item, i) in actionItems"
        :icon="item.icon"
        :filled="i % 2 === 0"
        minWidth="250"
        large
        :key="i"
        :to="{path: item.route}"
      >
        {{ item.label }}
      </my-btn>
    </page-heading>
  </section>
</template>

<script>
export default {
  name: 'HeadingSection',

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    actionItems() {
      if (this.currentUser) {
        return [
          { label: 'Formulaire', icon: 'mdi-file-document-outline', route: '/formulaire' },
          { label: 'Historique', icon: 'mdi-table', route: '/historique' }
        ]
      } else {
        return [
          { label: 'Inscription', icon: 'mdi-account-multiple-plus', route: '/register' },
          { label: 'Connexion', icon: 'mdi-login', route: '/login' },
        ]
      }
    }
  },

  components: {
    'my-btn': () => import('../../../core/BasicBtn.vue'),
    'page-heading': () => import('../../../core/PageHeading.vue')
  }
}
</script>
